<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Given that the
        <stemble-latex :content="'$K_{\\text{sp}}$'" />
        of zinc carbonate,
        <chemical-latex content="ZnCO3(s)," />
        is
        <stemble-latex :content="'$1.46\\times 10^{-10},$'" />
        determine the molar solubility,
        <stemble-latex :content="'$S$'" />
        , of the compound.
      </p>

      <calculation-input
        v-model="inputs.molarSolubility"
        :prepend-text="'$S$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question126',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molarSolubility: null,
      },
    };
  },
};
</script>
